<template>
    <div id="loading-container">
        <h1>Welcome</h1>
    </div>
</template>

<script>

export default {
    
}

</script>